import "./button-regular-orange.css";
import React from "react";
import LoadingIcon from "../loading-icon/loading-icon";

export interface ButtonOrangeProps {
    text: string,
    isLoading: boolean,
    isDeactivated: boolean,
    callbackFunc: Function
}

const ButtonRegularOrange = ({ text, isLoading, isDeactivated, callbackFunc }: ButtonOrangeProps) => {

    const handleClick = () => {
        if (!isLoading){
            callbackFunc();
        }
    }

    return (
        <button
            className={`orange_regular_button ${isDeactivated || isLoading ? 'deact' : ''}`}
            onClick={() => handleClick()}>
            {isLoading ? <LoadingIcon/> : <div className="orange_regular_button_text">{text}</div>}
        </button>
    );
}

export default ButtonRegularOrange;