import "./AppUser.css";
import Header from "../header/header";
import MainBanner from '../../components/main-page-banner/main-banner';
import MapWidget from "../../components/map-widget/map-widget";
import InteractiveCard from "../../components/interactive-card/interactive-card";
import ButtonWhite from "../../components/button-white/button-white";
import ModalAboutItem from "../modal-about-item/modal-about-item";
import ModalLogIn from "../modal-log-in/modal-log-in";
import ModalLogUp from "../modal-log-up/modal-log-up";
import ModalCart from "../modal-cart/modal-cart";
import ModalConfirmOrder from "../modal-confirm-order/modal-confirm-order";
import MenuRenderer from "../menu-renderer/menu-renderer"
import React, {useRef} from "react";

import AppDownloadImg from "./phone_app.png";
import DeliveryCompositionImg from "./delivery_bag_card_composition.png";
import CreateWokYourselfImg from "./create_wok_yourself.png";
import CreateHotDogYourselfImg from "./createityourself1_notext.png";
import {
    getArrayById,
    isValueInstalled
} from "../../service-functions/service-functions";
import ModalMyOrders from "../modal-my-orders/modal-my-orders";
import Footer from "./footer";
import {Helmet} from "react-helmet-async";

import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {setOpenedModal} from "../../redux/app/app-state";
import useGetMenu from "../../hooks/useGetMenu";
import useTheme from "../../hooks/useTheme";

function AppUser() {

    const menuState = useSelector((state: RootState) => state.menuState);
    const isDarkTheme = useSelector((state: RootState) => state.appState.isDarkTheme);
    const modalOpened = useSelector((state: RootState) => state.appState.modalOpened);
    const dishPicked = useSelector((state: RootState) => state.appState.dishPicked);

    const dispatch = useDispatch();

    const menuRef = useRef<HTMLHeadingElement | null>(null);
    const getMenu = useGetMenu();
    useTheme();
    const handleOpenAboutModal = (id: number) => dispatch(setOpenedModal({modalOpened: 'about', dishPicked: id}));
    const scrollTo = (target: React.RefObject<HTMLHeadingElement> | null) => {
        target?.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const handleDownloadApp = () => window.open('/app', '_blank')?.focus();
    const handleScrollToMenu = () => scrollTo(menuRef);

    const selectedDish = menuState.isMenuReceived && modalOpened == 'about' && dishPicked != null ? getArrayById(menuState.menu, dishPicked) : null;
    return (
        <>
            <Helmet>
                <title>{selectedDish ?
                    `${selectedDish.name} ${selectedDish?.about} заказать с доставкой в Лучегорске / iBurger` :
                    'iBurger - доставка еды в Лучегорске / меню'}</title>
                <meta
                    name="description"
                    content={selectedDish ? `${isValueInstalled(selectedDish?.composition) ?
                            `Состав: ${selectedDish?.composition}. ` : ''}${selectedDish?.about} Заказать ${selectedDish.name} онлайн с доставкой на дом. Доставка от 120 рублей` :
                        'Айбургер Лучегорск. Заказ еды онлайн. Привезём к вам домой вкуснейшую еду. Быстро и просто, закажите онлайн через сайт! От бургеров до лапши WOK и картошки фри. Большой ассортимент. Работаем с 11:00 до 22:00 без перерывов и выходных.'}/>
                <meta name="theme-color" content={isDarkTheme ? '#141414' : '#ffffff'}/>
            </Helmet>
            <Header
                handleScrollToUp={() => scrollTo(null)}
                handleScrollToMenu={handleScrollToMenu}
                handleDownloadApp={handleDownloadApp}
            />
            <main>
                <h1>Доставка еды в Лучегорске</h1>
                <MainBanner/>
                <MapWidget/>

                <div className="interactive_cards">
                    <InteractiveCard
                        img={AppDownloadImg}
                        title="В приложении удобнее"
                        subtitle="Установите наше приложение и заказывайте еду в пару кликов"
                        accentColor="#9cd1ff"
                        backgroundColor="linear-gradient(90deg, rgb(50 158 251) 0%, rgb(101, 183, 255) 100%)"
                        isLeft={true}
                    >
                        <ButtonWhite text="Скачать" onClick={handleDownloadApp}/>
                    </InteractiveCard>
                    <InteractiveCard
                        img={DeliveryCompositionImg}
                        title="Доставка от 120₽*"
                        subtitle="Доставим вам еду в любую точку Лучегорска"
                        accentColor="#ffe84f"
                        backgroundColor="linear-gradient(90deg, rgb(237, 206, 1) 0%, rgb(185 160 0) 100%)"
                        isLeft={false}
                    >
                        <ButtonWhite text="Заказать" isDeactivated={!menuState.isMenuReceived}
                                     onClick={handleScrollToMenu}/>
                    </InteractiveCard>
                </div>

                <div className="subtext_alert">*Доставка оплачивается отдельно. Точную стоимость доставки вы можете
                    уточнить в соответствии с тарифом такси. Доставка осуществляется только по Лучегорску.
                </div>


                <h1>На ваш выбор</h1>
                <div className="interactive_cards">
                    <InteractiveCard
                        img={CreateWokYourselfImg}
                        title="Собери свой WOK"
                        subtitle="Собери свою уникальную коробочку WOK, а мы ее приготовим!"
                        accentColor="#ffa4a4"
                        backgroundColor="linear-gradient(90deg, rgb(255, 0, 0) 0%, rgb(255 69 69) 100%)"
                        isLeft={true}
                    >
                        <ButtonWhite text="Собрать" isDeactivated={!menuState.isMenuReceived}
                                     onClick={() => handleOpenAboutModal(13)}/>
                    </InteractiveCard>
                    <InteractiveCard
                        img={CreateHotDogYourselfImg}
                        title="Собери свой Супер-дог"
                        subtitle="Приготовим хот-дог из твоих ингридиентов!"
                        accentColor="#ffaf7d"
                        backgroundColor="linear-gradient(90deg, rgb(255 136 60) 0%, rgb(195, 76, 0) 100%)"
                        isLeft={false}
                    >
                        <ButtonWhite text="Собрать" isDeactivated={!menuState.isMenuReceived}
                                     onClick={() => handleOpenAboutModal(38)}/>
                    </InteractiveCard>
                </div>

                <h1 ref={menuRef}>Меню</h1>

                <MenuRenderer onItemClicked={handleOpenAboutModal} />
            </main>
            <Footer/>
            <ModalAboutItem
                handleForceUpdateMenu={getMenu}
            />
            <ModalLogIn
                handleScrollToMenu={handleScrollToMenu}
            />
            <ModalLogUp
                handleScrollToMenu={handleScrollToMenu}
            />
            <ModalCart
                handleForceUpdateMenu={getMenu}
                handleScrollToMenu={handleScrollToMenu}
            />
            <ModalConfirmOrder />
            <ModalMyOrders/>
        </>
    );
}

export default AppUser;