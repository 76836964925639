import React, {useEffect, useRef} from "react";
import { CSSTransition } from 'react-transition-group';
import "./modal.css";
import ButtonOrange, { ButtonOrangeProps } from "../../components/button-orange/button-orange";

interface ModalProps {
    isVisible: boolean,
    closable: boolean,
    handleClose: Function,
    children?: React.ReactNode
    button?: ButtonOrangeProps
}

function Modal({ isVisible, closable, handleClose, children, button }: ModalProps){
    const modalRef = useRef(null);
    const modalBodyRef = useRef<HTMLHeadingElement | null>(null);

    const handleLocalButtonClose = () => handleClose();

    const modalBody =
        <div className="modalbkg" ref={modalRef}>
        <div className="modal">
            <div className="modal_top">
                {closable && (<button className="modal_top_button" onClick={handleLocalButtonClose}>Закрыть</button>)}
            </div>
            <div className="modal_body" ref={modalBodyRef}>
                {children}
            </div>
            {
                button != null && (
                    <div className="modal_bottom">
                        <ButtonOrange {...button} />
                    </div>
                )
            }
        </div>
    </div>;

    useEffect(() =>{
        if (isVisible){
            const elem = modalBodyRef.current;
            elem?.scrollTo({ top:elem?.scrollHeight });
            elem?.scrollTo({ top:0, behavior: 'smooth' });
        }
    },[isVisible]);

    return(
        <CSSTransition
            in={isVisible}
            nodeRef={modalRef}
            timeout={150}
            classNames="modal_anim"
            unmountOnExit
        >
            {modalBody}
        </CSSTransition>
    );
}
export default Modal;