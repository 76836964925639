import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {getCookie} from "../../service-functions/service-functions";

interface appState {
    isDarkTheme: boolean,
    modalOpened: "login" | "logup" | "myorders" | "confirmorder" | "cart" | "about" | null,
    dishPicked: number|null,
    deliveryConditionsText: string
}
type setOpenedModalProps = Pick<appState, 'modalOpened'> & {
    dishPicked?: number;
}

const getInitialTheme = () => {
    const cookie = getCookie('theme');
    if (cookie != null) return cookie == 'dark'
    return window.matchMedia("(prefers-color-scheme: dark)").matches;
};
const initialState: appState = {
    isDarkTheme: getInitialTheme(),
    modalOpened:null,
    dishPicked:null,
    deliveryConditionsText:''
};

const appStateSlice = createSlice({
    name: "appState",
    initialState,
    reducers: {
        switchAppTheme: (state) => {
            state.isDarkTheme = !state.isDarkTheme;
        },
        setOpenedModal: (state, action:PayloadAction<setOpenedModalProps>) => {
            state.modalOpened = action.payload.modalOpened;
            const dish = action.payload.dishPicked;
            if (dish !== undefined) state.dishPicked = dish;
        },
        setDeliveryConditionsText: (state, action: PayloadAction<Pick<appState, 'deliveryConditionsText'>>) => {
            state.deliveryConditionsText = action.payload.deliveryConditionsText;
        }
    }
});

export const {switchAppTheme, setOpenedModal, setDeliveryConditionsText} = appStateSlice.actions;
export default appStateSlice.reducer;