import {menuArrayReference, userOrderRef} from "../../types";
import { getArrayById } from "../../service-functions/service-functions";
import CartItem from "../../components/cart-item/cart-item";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";

interface PropsReference {
    hasButtons: boolean,
    showAvailability: boolean,
    showDeliverability: boolean,
    userOrder: userOrderRef[],
    addItemHandle?: (idInOrder: number) => void,
    removeItemHandle?: (idInOrder: number) => void,
}
interface userOrderRenderFormat extends userOrderRef{
    amount:number,
    indexInParent: number
}
function CartRenderer({ hasButtons, userOrder, addItemHandle, removeItemHandle, showAvailability, showDeliverability }: PropsReference){
    const menuState = useSelector((state: RootState) => state).menuState;
    const parseUserOrderForRender = (order: userOrderRef[]): userOrderRenderFormat[] =>{
        const input = order;
        const output: userOrderRenderFormat[] = [];
        const searchItem = (id: number): number => {
            for(let i = 0;i<output.length;i++){
                if (output[i].id==id && output[i].additionals.length==0){
                    return i;
                }
            }
            return -1;
        }

        for(let i = 0;i<input.length;i++){
            if (input[i].additionals.length==0){
                const pos = searchItem(input[i].id);
                if (pos!=-1){
                    output[pos] = {...output[pos], amount:output[pos].amount+1, indexInParent: i};
                }else {
                    output.push({...input[i], amount:1, indexInParent: i});
                }
            }else {
                output.push({...input[i], amount:1, indexInParent: i});
            }
        }
        return output;
    }

    const parsedUserOrder = parseUserOrderForRender(userOrder);
    return <>
        {
            parsedUserOrder.map((item) => {
                const menuItem = getArrayById(menuState.menu,item.id)!;
                return (<CartItem
                    key={"CartItem_"+item.indexInParent+"menu_"+menuItem.id}
                    itemId={Number(menuItem.id)}
                    idInOrder={item.indexInParent}
                    hasButtons={hasButtons}
                    img={menuItem.img}
                    price={Number(menuItem.price)}
                    name={menuItem.name}
                    available={showAvailability ? menuItem.avalible=="1" : true}
                    deliverable={showDeliverability ? menuItem.delivery_avalible=="1" : true}
                    additionalsList={item.additionals}
                    amount={item.amount}
                    addItemHandle={addItemHandle}
                    removeItemHandle={removeItemHandle}
                />);
            })
        }
    </>;
}
export default CartRenderer;