import "./button-white.css";

interface propsReference {
    text: string,
    isDeactivated?: boolean,
    onClick: Function
}

function ButtonWhite({ text, isDeactivated, onClick }: propsReference){

    const clicked = () =>{
        if (!isDeactivated){
            onClick();
        }
    }

    return(
        <div className={`button_white ${isDeactivated==true ? 'deact' : ''}`} onClick={clicked}>{text}</div>
    );
}

export default ButtonWhite;