import { menuArrayReference, userOrderRef } from "../types";

/**
 * arrays
 */
export function getArrayById<T extends { id: string | number }>(array: T[], id: string | number | null): T | null {
    if (id == null) return null;
    for (let i = 0; i < array.length; i++) {
        if (Number(array[i]["id"]) === Number(id)) {
            return array[i];
        }
    }
    return null;
}
export function convertStringToArray(string: string | null | undefined): string[]{
    if (!isValueInstalled(string)) return [];
    return string!.split(',');
}
export function convertArrayToString(array: Array<any>, proceedInside: boolean = false): string{
    if (array.length===0) return "";
    let output = "";
    for (let i=0;i<array.length;i++){
        const item = proceedInside ? convertArrayToString(array[i]) : array[i];
        if (output===""){
            output = item;
        }else {
            output = output+','+item;
        }
    }
    return output;
}
export function convertRequestToUserOrderType(request: any): userOrderRef[]{
    const additionals = request[1] as string[];
    const userOrder: userOrderRef[] = [];

    for (let i=0; i<additionals.length; i++) {
        if(additionals[i].includes('id')){
            const itemId = additionals[i].replace('id','');
            const SubArr = [];
            for (let g=i+1; g<additionals.length; g++)
            {
                const subItem = additionals[g];
                if(subItem.includes('id') || Number(subItem) == -1) break;
                SubArr.push(Number(additionals[g]));
            }
            userOrder.push({
                id: Number(itemId),
                additionals:SubArr
            });
        }
    }
    return userOrder;
}

/**
 * texts
 */
export const isNumeric = (n: any) => !isNaN(n);
export function isValueInstalled(string: String | null | undefined){
    return !(string === "" || string === " " || string === "null" || string == null);
}

export function getCookie(name: String) {
    let matches = document.cookie.match(new RegExp(
        "(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, '\\$1') + "=([^;]*)"
    ));
    return matches ? decodeURIComponent(matches[1]) : null;
}
export function setCookie(name: String, value: String ,maxAge: Number, path: String, secure: boolean = false){
    const secureString = secure ? 'true' : 'false';
    document.cookie = name+"="+value+"; max-age="+maxAge+"; path="+path+"; secure="+secureString;
}
export function removeCookie(name: String, path: String){
    document.cookie = name+"=; max-age=-10; path="+path;
}


/**
 * menu
 */
export function countElementPrice(menuArray: menuArrayReference, menuItemId: number, userSelections: Array<number[]> = []){
    let totalPrice = 0;
    const menuElem = getArrayById(menuArray.menu,menuItemId);
    if (menuElem == null) return null;
    const elemPrice = Number(menuElem.price);
    if (elemPrice!=-1) totalPrice+=Number(menuElem.price);
    //const filterAvalibleForSelect = filterSelectableSetions(menuArray,convertStringToArray(menuElem.additionals));
    for (const key of userSelections.keys()) {
        const item = userSelections[key];
        if (item!=null){
            for(let s = 0; s<item.length;s++){
                totalPrice+=Number(getArrayById(menuArray.additionals,item[s])!.price);
            }
        }
    }
    return totalPrice;
}

//filter sections to find only selectable sections for user
export function filterSelectableSetions(menuArray: menuArrayReference, itemAdditionals: number[] | string[], onlyRequireSelect: boolean = false){
    const additionalsToSelect: number[] = [];
    for(let i = 0; i<itemAdditionals.length;i++){
        const section = getArrayById(menuArray.additionalsSections,itemAdditionals[i])!;
        const listOfItems = convertStringToArray(section.additionals_list);
        let allChecked = true;
        listOfItems.every(additIdItem => {
            if (Number(getArrayById(menuArray.additionals,additIdItem)!.checked) === 0){
                allChecked = false
            }
            return allChecked;
        });
        if(!allChecked){
            if (onlyRequireSelect){
                if (Number(section.selectrequare)==1){
                    additionalsToSelect.push(Number(itemAdditionals[i]));
                }
            }else {
                additionalsToSelect.push(Number(itemAdditionals[i]));
            }
        }
    }
    return additionalsToSelect;
}