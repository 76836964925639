import "./cart-item.css";
import plusIcon from "./add_icon.png";
import minusIcon from "./minus_icon.png";
import {convertStringToArray, getArrayById, isValueInstalled} from "../../service-functions/service-functions";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";

interface PropsReference {
    idInOrder: number,
    itemId: number,
    additionalsList: number[],
    hasButtons: boolean,
    img: string,
    price: number,
    name: string,
    amount: number,
    available: boolean,
    deliverable: boolean,
    addItemHandle?: (idInOrder: number) => void,
    removeItemHandle?: (idInOrder: number) => void
}
function CartItem({img, name, price, amount, hasButtons, addItemHandle, removeItemHandle, idInOrder, additionalsList, itemId, available, deliverable}: PropsReference){
    const menuState = useSelector((state: RootState) => state.menuState);
    const generateAdditionalsListToRender = (): number[] =>{
        //search permanent selected items
        //return type is: permanent selected items + additionalsList selected by user
        let output: number[] = [];
        const additionalsSecItemList = convertStringToArray(getArrayById(menuState.menu, itemId)!.additionals);
        for (let i=0; i<additionalsSecItemList.length;i++){
            const foundedAdditionalSec = getArrayById(menuState.additionalsSections,additionalsSecItemList[i])!;
            const additionalsItemList = convertStringToArray(foundedAdditionalSec.additionals_list);
            for (let t=0; t<additionalsItemList.length;t++){
                const foundAdditional = getArrayById(menuState.additionals,additionalsItemList[t])!;
                if (foundAdditional.checked=="0"){
                    //if element is selectable, return value
                    return [...output, ...additionalsList];
                }
            }
            output = [...output, ...additionalsItemList.map(item => Number(item))];
        }
        return [...output, ...additionalsList];
    }

    return (
        <div className="cart_item">
            <div className="cart_item_side">
                <img src={isValueInstalled(img) ? img : '/images/ui-icons/burgerNonImg.png'} className="cart_item_img" alt="item"/>
                <div className="cart_item_center">
                    <div className="cart_item_center_title">{name}</div>
                    {price!=-1 && <div className="cart_item_center_price">{`${price}₽`}</div>}
                    <div className="cart_item_center_additionals">
                        {!available && <b className="cart_item_red_warning">Нет в наличии</b>}
                        {!deliverable && <b className="cart_item_red_warning">Не доставляем, только самовывоз.</b>}
                        {
                            generateAdditionalsListToRender().map(item => {
                                const itemAdditional = getArrayById(menuState.additionals,item)!;
                                return <div key={"additionalInCartItem_"+item+"menu_"+itemAdditional.id}>{`${itemAdditional.name} ${itemAdditional.price}₽`}</div>
                            })
                        }
                    </div>
                </div>
            </div>
            <div className="cart_item_side">
                <div className={`cart_item_actions ${hasButtons ? 'act' : ''}`}>
                    <div className="cart_item_actions_button" onClick={() => removeItemHandle?.(idInOrder)}>
                        <img src={minusIcon} className="cart_item_actions_button_icon" alt="button remove"/>
                    </div>
                    <div className="cart_item_actions_counter">{amount}</div>
                    <div className="cart_item_actions_button" onClick={() => addItemHandle?.(idInOrder)}>
                        <img src={plusIcon} className="cart_item_actions_button_icon" alt="button add"/>
                    </div>
                </div>
            </div>
        </div>
    );
}
export default CartItem;