import React from 'react';
import ReactDOM from 'react-dom/client';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import './index.css';
import store from "./redux/store";
import AppUser from './containers/app-user/AppUser';
import { Provider } from "react-redux";
import {HelmetProvider} from "react-helmet-async";
import { AlertMessageProvider } from "./components/alert-message/AlertMessageContext";


const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <Provider store={store}>
            <HelmetProvider>
                <AlertMessageProvider>
                    <BrowserRouter>
                        <Routes>
                            <Route path="/" Component={AppUser} />
                        </Routes>
                    </BrowserRouter>
                </AlertMessageProvider>
            </HelmetProvider>
        </Provider>
    </React.StrictMode>
);