import "./loading-icon.css";
import icon from "./loading_icon.svg";

function LoadingIcon(){

    return(
        <img className="loading_icon" src={icon} alt="loading"/>
    );
}

export default LoadingIcon;