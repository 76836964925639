import "./modal-log-in.css";
import Modal from "../modal/modal";
import React, { useState } from "react";
import { ButtonOrangeProps } from "../../components/button-orange/button-orange";
import Link from "../../components/link/link";
import InputText from "../../components/input-text/input-text";
import {useAlertMessage} from "../../components/alert-message/AlertMessageContext";
import {
    convertRequestToUserOrderType,
    isNumeric,
    isValueInstalled,
    setCookie
} from "../../service-functions/service-functions";
import { signInRequest } from "../../requests";
import {userOrderRef} from "../../types";
import {useDispatch, useSelector} from "react-redux";
import {setUserOrder, updateUserAuth} from "../../redux/user/user-state";
import {setOpenedModal} from "../../redux/app/app-state";
import {RootState} from "../../redux/store";

interface PropsReference {
    handleScrollToMenu: ()=> void
}

function ModalLogIn({ handleScrollToMenu }: PropsReference){
    const modalOpened = useSelector((state: RootState) => state.appState.modalOpened);
    const [isLoading, setLoading] = useState(false);
    const [enteredLogin, setEnteredLogin] = useState<string>("");
    const [enteredPassword, setEnteredPassword] = useState<string>("");

    const alertShowMessage = useAlertMessage();
    const dispatch = useDispatch();

    const handleClose = () => {

        //reset all states to default
        setLoading(false);
        setEnteredLogin("");
        setEnteredPassword("");
        dispatch(setOpenedModal({ modalOpened:null }));
    };

    const handleRegOpen = () => dispatch(setOpenedModal({ modalOpened:'logup' }));

    const handleRequest = () =>{
        if (!isValueInstalled(enteredLogin)){
            alertShowMessage("Введите телефон",false);
        }else if (!isValueInstalled(enteredPassword)){
            alertShowMessage("Введите пароль",false);
        }else {
            setLoading(true);
            signInRequest(enteredLogin,enteredPassword).then((result) =>{
                setCookie("authtoken",result["user_token"],31536000,"/",true);
                dispatch(updateUserAuth({
                    clientToken:result["user_token"].toString(),
                    authStatus:true,
                    userName:result["name"],
                    userDefaultAddress:result["address"]
                }));
                const userOrder = result['user_order'];
                dispatch(setUserOrder({
                    userOrder:convertRequestToUserOrderType(userOrder),
                    orderTotal: Number(userOrder[2])
                }));
                alertShowMessage("Вы успешно вошли в аккаунт",true);
                handleClose();
                handleScrollToMenu();
            }).catch((error) =>{
                switch (error.message){
                    case '401':
                        alertShowMessage("Неверный логин или пароль",false);
                        break;
                    default:
                        if (isNumeric(error.message)){
                            alertShowMessage("Server error: "+error.message, false);
                        }else {
                            alertShowMessage('Проверьте подключение к интернету',false);
                        }
                }
            }).finally(() => setLoading(false));
        }
    }

    const buttonModalConfigure: ButtonOrangeProps = {
        text: "Войти",
        isLoading: isLoading,
        isDeactivated: false,
        onClick: handleRequest
    };
    return(
        <Modal isVisible={modalOpened=='login'}
               closable={!isLoading}
               button={buttonModalConfigure}
               handleClose={handleClose}>
            <center className={`opacity_anim_controller ${isLoading ? 'pending' : ''}`}>
                <h3 className="modal_auth_title">Вход</h3>
                <InputText type="text" placeholder="Телефон" onChangeText={setEnteredLogin} value={enteredLogin} fill={false}/>
                <InputText type="password" placeholder="Пароль" onChangeText={setEnteredPassword} value={enteredPassword} fill={false}/>
                <div className="modal_auth_subtitle">Нет аккаунта? <Link onClick={handleRegOpen}>Зарегестрироваться</Link></div>
            </center>
        </Modal>);
}
export default ModalLogIn;