import {getMenuArrayRequest} from "../requests";
import {setDeliveryConditionsText, setOpenedModal} from "../redux/app/app-state";
import {updateMenu} from "../redux/menu/menuState";
import {clearUserAuth, updateUserAuth} from "../redux/user/user-state";
import {convertRequestToUserOrderType, getCookie, removeCookie} from "../service-functions/service-functions";
import {useCallback, useEffect, useState} from "react";
import {useAlertMessage} from "../components/alert-message/AlertMessageContext";
import {useDispatch} from "react-redux";

export default function useGetMenu(){
    const [timerUpdateId, setTimerUpdateId] = useState<null | number>(null);
    const alertShowMessage = useAlertMessage();
    const dispatch = useDispatch();

    const getMenu = useCallback(() => {
        getMenuArrayRequest().then((result) => {
            dispatch(setDeliveryConditionsText({ deliveryConditionsText: result['delivery_text'] }));
            dispatch(updateMenu({
                menu: result['catalog_main'],
                menuSections: result['catalog_sections'],
                additionals: result['catalog_additionals'],
                additionalsSections: result['catalog_additionals_sections']
            }));
            if (result['authorized'] as boolean) {
                const orderData = result['user_order'];
                dispatch(updateUserAuth({
                    clientToken: getCookie("authtoken"),
                    authStatus: true,
                    userName: result['user_name'],
                    userDefaultAddress: result["user_default_address"],
                    userOrder: convertRequestToUserOrderType(orderData),
                    orderTotal: Number(orderData[2])
                }));
                if (document.location.hash == '#orders') {
                    dispatch(setOpenedModal({modalOpened: "myorders"}));
                    document.location.hash = '';
                }
            } else {
                dispatch(clearUserAuth());
                removeCookie("authtoken", "/");
            }

            if (timerUpdateId == null) setTimerUpdateId(setInterval(getMenu, 300000) as unknown as number);

        }).catch((error) => {
            if (!isNaN(error.message)) {
                //TODO figure this alert thing out
                alertShowMessage('Не удалось загрузить меню: ошибка сервера', false);
            } else {
                alertShowMessage('Проверьте подключение к интернету', false);
                getMenu();
            }
        });
    },[timerUpdateId]);

    useEffect(() => {
        getMenu();
        return () => {
            if (timerUpdateId != null) clearInterval(timerUpdateId);
        }
    }, []);
    return getMenu;
}