import "./order-item.css";
import CartRenderer from "../../containers/cart-renderer/cart-renderer";
import {userOrderRef} from "../../types";
import React from "react";
import {isValueInstalled} from "../../service-functions/service-functions";
import SmallButtonOrange from "../../components/small-button-orange/small-button-orange";

interface PropsReference {
    date: string,
    orderId: number,
    type: number,
    status: number,
    total: number,
    order: userOrderRef[],
    payUrl:string,
    cancelComment:string,
    buttonEnabled:boolean,
    handleRepeatOrder:(id: number)=>void
}
function OrderItem({ date, orderId, type, status, total, order, cancelComment, handleRepeatOrder, buttonEnabled, payUrl }: PropsReference){

    const orderDate = new Date(date);
    orderDate.setMinutes(orderDate.getMinutes() + 90);
    const currentDate = new Date();

    const statuses = [
        'Ожидает оплаты',
        'Оплачен',
        'Готовится',
        'Передаётся в доставку',
        currentDate>orderDate?'Завершен':'Готов, передан в доставку',
        'Готов к выдаче',
        'Завершен',
        'Отменён',
    ];
    const statusesDescriptions = [
        'Заказ ожидает оплаты. Как только вы оплатите заказ, мы сразу начнём готовить его.',
        'Оплата прошла успешно, мы начнём готовить ваш заказ в течении 5 минут. Статус заказа можно отслеживать здесь. Если есть вопросы по заказу, вы можете позвонить ',
        'Заказ передан на кухню и уже готовиться. Статус заказа можно отслеживать здесь.',
        'Заказ готов и передается курьеру. Статус заказа можно отслеживать здесь.',
        currentDate>orderDate?'':'Заказ готов. Курьер уже везёт его вам и при необходимости свяжется с вами по указанному номеру.',
        'Заказ готов. Заберите его как можно скорее, чтобы он не остыл.',
        '',
        isValueInstalled(cancelComment) ? cancelComment : 'Заказ отменён, потому что оплата не прошла. Вы можете повторить заказ.',
    ];
    const statusesColors = [
        'red',
        'orange',
        'yellow',
        'yellow',
        '',
        '',
        '',
        'red',
    ];

    const clickedOrderButton = () => {
        if (status == 0){
            document.location.href = payUrl;
        }else {
            handleRepeatOrder(orderId);
        }
    };

    return <div className="user_ordersList_item">
        <div className="user_ordersList_item_date user_selection_allowed">{date}</div>
        <div className="user_ordersList_item_top">
            <div className="user_ordersList_item_top_side">
                <div className="user_ordersList_item_top_title user_selection_allowed">Заказ {orderId}</div>
            </div>
            <div className="user_ordersList_item_top_side">
                <div className="user_ordersList_item_top_row">
                    <div className="user_ordersList_item_top_popup">{type == 0 ? 'Самовывоз' : 'Доставка'}</div>
                    <div className={`user_ordersList_item_top_popup ${statusesColors[status]}`}>{statuses[status]}</div>
                </div>
                <div className="user_ordersList_item_top_subtitle user_selection_allowed">{total}RUB</div>
            </div>
        </div>
        <div className="user_ordersList_item_status_descr user_selection_allowed">{statusesDescriptions[status]}</div>
        <div className="user_ordersList_item_orderInner">
            <CartRenderer
                hasButtons={false}
                showDeliverability={false}
                showAvailability={false}
                userOrder={order}
            />
        </div>
        <center>
            <SmallButtonOrange text={status == 0 ? 'Оплатить заказ' : 'Повторить заказ'} isDeactivated={!buttonEnabled} onClick={clickedOrderButton}/>
        </center>
    </div>;
}
export default OrderItem;