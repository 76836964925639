import "./modal-cart.css";
import Modal from "../modal/modal";
import React, { useState } from "react";
import { ButtonOrangeProps } from "../../components/button-orange/button-orange";
import { useAlertMessage } from "../../components/alert-message/AlertMessageContext";
import { userOrderRef } from "../../types";
import CartRenderer from "../cart-renderer/cart-renderer";
import {addToOrderRequest, removeCartItem } from "../../requests";
import {
    convertArrayToString,
    convertRequestToUserOrderType, getArrayById,
    isNumeric
} from "../../service-functions/service-functions";
import frenchFries from "./french_fries.png";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {clearUserAuth, setUserOrder} from "../../redux/user/user-state";
import {setOpenedModal} from "../../redux/app/app-state";

interface PropsReference {
    handleForceUpdateMenu: () => void,
    handleScrollToMenu: ()=> void
}

const ruWordDeclination = (number: number) =>{
    const num = String(number);
    const lastLetter = Number(num[num.length-1]);
    if(lastLetter == 1)
        return 'блюдо'
    else if(lastLetter == 2)
        return 'блюда'
    else if(lastLetter == 3)
        return 'блюда'
    else if(lastLetter == 4)
        return 'блюда'
    else
        return 'блюд'
}

function ModalCart({ handleForceUpdateMenu, handleScrollToMenu }: PropsReference){
    const modalOpened = useSelector((state: RootState) => state.appState.modalOpened);
    const [isLoading, setLoading] = useState(false);

    const alertShowMessage = useAlertMessage();
    const menuState = useSelector((state: RootState) => state).menuState;
    const userOrder = useSelector((state: RootState) => state.userState.userOrder);
    const orderTotal = useSelector((state: RootState) => state.userState.orderTotal);
    const dispatch = useDispatch();
    const handleClose = () => {

        //reset all states to default
        setLoading(false);
        dispatch(setOpenedModal({ modalOpened:null }));
    };
    const handleModalConfirm = () => {
        handleClose();
        if (userOrder.length>0)
            dispatch(setOpenedModal({ modalOpened:'confirmorder' }));
        else
            handleScrollToMenu()
    };

    const addItemHandle = (idInOrder: number) =>{
        if (!isLoading){
            setLoading(true);
            const item = userOrder[idInOrder];
            addToOrderRequest(item.id,convertArrayToString(item.additionals)).then((result) =>{
                dispatch(setUserOrder({
                    userOrder:convertRequestToUserOrderType(result),
                    orderTotal: Number(result[2])
                }));
            }).catch((error) =>{
                switch (error.message) {
                    case '404':
                        alertShowMessage("Товара нет в наличии", false);
                        const menuItem = getArrayById(menuState.menu, item.id)!;
                        if (menuItem.avalible=="1"&&menuItem.deleted=="0"){
                            handleForceUpdateMenu();
                        }
                        break;
                    case '401':
                        alertShowMessage("Для добавления блюд в корзину нужно войти в аккаунт", false);
                        handleClose();
                        dispatch(clearUserAuth());
                        dispatch(setOpenedModal({ modalOpened:'login' }));
                        return;
                    default:
                        if (isNumeric(error.message)){
                            alertShowMessage("Server error: "+error.message, false);
                        }else {
                            alertShowMessage('Проверьте подключение к интернету',false);
                        }
                }
            }).finally(() => setLoading(false));
        }
    }
    const removeItemHandle = (idInOrder: number) =>{
        if (!isLoading){
            setLoading(true);
            removeCartItem(idInOrder).then((result) =>{
                dispatch(setUserOrder({
                    userOrder:convertRequestToUserOrderType(result),
                    orderTotal: Number(result[2])
                }));
            }).catch((error) =>{
                switch (error.message) {
                    case '401':
                        alertShowMessage("Для добавления блюд в корзину нужно войти в аккаунт", false);
                        handleClose();
                        dispatch(clearUserAuth());
                        dispatch(setOpenedModal({ modalOpened:'login' }));
                        return;
                    default:
                        if (isNumeric(error.message)){
                            alertShowMessage("Server error: "+error.message, false);
                        }else {
                            alertShowMessage('Проверьте подключение к интернету',false);
                        }
                }
            }).finally(() => setLoading(false));
        }
    }
    const removeAllHandle = () =>{
        if (!isLoading){
            setLoading(true);
            removeCartItem(null).then((result) =>{
                dispatch(setUserOrder({
                    userOrder:convertRequestToUserOrderType(result),
                    orderTotal: Number(result[2])
                }));
                alertShowMessage("Корзина очищена", true);
            }).catch((error) =>{
                switch (error.message) {
                    case '401':
                        alertShowMessage("Для добавления блюд в корзину нужно войти в аккаунт", false);
                        handleClose();
                        dispatch(clearUserAuth());
                        dispatch(setOpenedModal({ modalOpened:'login' }));
                        return;
                    default:
                        if (isNumeric(error.message)){
                            alertShowMessage("Server error: "+error.message, false);
                        }else {
                            alertShowMessage('Проверьте подключение к интернету',false);
                        }
                }
            }).finally(() => setLoading(false));
        }
    }

    const buttonModalConfigure: ButtonOrangeProps = {
        text: userOrder.length>0 ? "Оформить заказ": "Перейти в меню",
        isLoading: isLoading,
        isDeactivated: false,
        onClick: handleModalConfirm
    };
    return(
        <Modal isVisible={modalOpened=='cart'}
               closable={!isLoading}
               button={buttonModalConfigure}
               handleClose={handleClose}>
            {userOrder.length == 0 && <center className="orders_empty">
                <img src={frenchFries} className="orders_empty_img" alt="Empty cart icon"/>
                <div className="orders_empty_text">Ваша корзина пуста</div>
            </center>}
            {userOrder.length > 0 &&
                <>
                    <center className={`cart_items_area opacity_anim_controller ${isLoading ? 'pending' : ''}`}>
                        <CartRenderer
                            hasButtons={true}
                            userOrder={userOrder}
                            showAvailability={true}
                            showDeliverability={true}
                            addItemHandle={addItemHandle}
                            removeItemHandle={removeItemHandle}
                        />
                    </center>
                    <div className="cart_total_text user_selection_allowed">{`${userOrder.length} ${ruWordDeclination(userOrder.length)} на сумму ${orderTotal} рублей`}</div>
                    <div className="cart_clearall_div">
                        <button className="cart_clearall_button" onClick={removeAllHandle}>Очистить корзину</button>
                    </div>
                </>
            }
        </Modal>);
}
export default ModalCart;