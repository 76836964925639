import "./menu-renderer.css";
import MenuItem from "../../components/menu-item/menu-item";
import MenuSkeletonItem from "../../components/menu-item/menu-skeleton-item";
import React from "react";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";


interface PropsReference {
    onItemClicked: (id: number) =>void
}

function MenuRenderer({ onItemClicked }: PropsReference){
    const menuState = useSelector((state: RootState) => state).menuState;
    if (!menuState.isMenuReceived) {
        return <div className="menu_block_sectionList">{Array(15).fill(undefined).map((_, index) => <MenuSkeletonItem
            key={'skel_' + index}/>)}</div>;
    }
    return (<>
        {
            (menuState.isMenuReceived) && menuState.menuSections.map(secValue => {
                return <div key={'sectionGroup'+secValue.id} className="menu_block_sectionGroup">
                    <h2 key={'sectionName'+secValue.id} className="section_title">{secValue.name}</h2>
                    <div key={'section'+secValue.id} className="menu_block_sectionList">
                        {
                            menuState.menu.filter(
                                item => Number(item.sectionid) === Number(secValue.id) && Number(item.deleted) === 0
                            ).map(
                                menuValue =>
                                    <MenuItem key={'MenuItem'+menuValue.id}
                                              id={Number(menuValue.id)}
                                              onClick={() => onItemClicked(Number(menuValue.id))}
                                              name={menuValue.name}
                                              price={Number(menuValue.price)}
                                              isAvalible={Number(menuValue.avalible) === 1}
                                              img={menuValue.img}
                            />)
                        }
                    </div>
                </div>;
            })
        }
    </>);
}

export default MenuRenderer;