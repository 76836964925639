import "./check-button.css";
import checkIcon from "./checked_icon.png";

interface PropsReference {
    icon: string,
    text: string,
    subtext?:string,
    checked: boolean,
    onClick?:() =>void
}
function CheckButton({ icon, text, checked, subtext, onClick }: PropsReference){

    const handleClicked = () =>{
        onClick?.();
    }

    return <div className="modal_check_button" onClick={handleClicked}>
        <div className="modal_check_button_left">
            <img src={icon} className="modal_check_button_img"/>
            <div className="modal_check_button_name">{text}</div>
            <div className="modal_check_button_subtext">{subtext}</div>
        </div>
        <div className={`modal_check_button_check ${checked ? 'act' : ''}`}>
            <img src={checkIcon} className="modal_check_button_check_img"/>
        </div>
    </div>;
}

export default CheckButton;