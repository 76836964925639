import "./link.css";
import React from "react";

interface PropsReference {
    onClick?: () =>void,
    href?: string,
    children?: React.ReactNode
}
function Link({ onClick, href, children}: PropsReference){
    const clickHandle = () => onClick?.();

    return <a onClick={clickHandle} href={href}>{children}</a>;
}
export default Link;