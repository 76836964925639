import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {menuArrayReference} from "../../types";
import {isValueInstalled} from "../../service-functions/service-functions";

//getting menu from injected js
const initialData = (window as any).__INITIAL_DATA__;
const menuArray = isValueInstalled(initialData) ? JSON.parse(initialData) : null;

const initialState: menuArrayReference = {
    menu:menuArray ? menuArray['catalog_main'] : [],
    menuSections:menuArray ? menuArray['catalog_sections'] : [],
    additionals:menuArray ? menuArray['catalog_additionals'] : [],
    additionalsSections:menuArray ? menuArray['catalog_additionals_sections'] : [],
    isMenuReceived:menuArray!=null
};

const menuStateSlice = createSlice({
    name: "menuState",
    initialState,
    reducers: {
        updateMenu: (state, action: PayloadAction<Omit<menuArrayReference, 'isMenuReceived'>>) => {
            state.menu = action.payload.menu;
            state.menuSections = action.payload.menuSections;
            state.additionals = action.payload.additionals;
            state.additionalsSections = action.payload.additionalsSections;
            state.isMenuReceived = true;
        }
    }
});

export const { updateMenu } = menuStateSlice.actions;
export default menuStateSlice.reducer;