import "./button-black.css";
import React from "react";

interface propsReference {
    icon: string,
    text: string,
    url: string,
    isNewTab: boolean
}

function ButtonBlack({ icon, text, url, isNewTab }: propsReference){
    return(
        <a className="map_widget_btn"
           href={url}
           rel="noopener noreferrer"
           target={isNewTab ? '_blank' : undefined}
        >
            <img
                className="map_widget_btn_img"
                src={icon}
                alt="icon"
            />
            <div className="map_widget_btn_txt">{text}</div>
        </a>
    );
}

export default ButtonBlack;