import "./menu-item.css";
import React from "react";
import {isValueInstalled} from "../../service-functions/service-functions";

interface PropsReference {
    id: number,
    img: string,
    name: string,
    price: number,
    isAvalible: boolean,
    onClick: Function
}

function MenuItem({ id, img, name, price, isAvalible = false, onClick }: PropsReference){

    const handleLocalItemClick = () => onClick();
    const handleButtonClick = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.preventDefault();

    return(
        <div className={`menu_block_sectionList_item ${!isAvalible ? 'deact' : ''}`} id={'ib_menu_item_'+id} onClick={handleLocalItemClick}>
            <img src={isValueInstalled(img) ? img : '/images/ui-icons/burgerNonImg.png'} className="menu_block_sectionList_item_img" alt={name}/>
            <div className="menu_block_sectionList_item_center">
                <div className="menu_block_sectionList_item_title">{name}</div>
                <div className="menu_block_sectionList_item_downDiv">
                    <div className={`menu_block_sectionList_item_downDiv_price ${!isAvalible ? 'missing' : ''}`}>{!isAvalible ? 'Будет позже' : price!=-1 ? `${price} ₽` : 'Выбрать'}</div>
                    <a className={'menu_block_sectionList_item_downDiv_addToCartBtn' + (isAvalible ? ' act': '')}
                       id="ib_menu_item_button_1"
                       href={'/?dish='+id}
                       onClick={handleButtonClick}
                    >{isAvalible ? 'В корзину' : 'Будет позже'}</a>
                </div>
            </div>
        </div>
    );
}

export default MenuItem;