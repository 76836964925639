import "./map_widget.css";
import ButtonBlack from "../button-black/button-black";
import YandexMapsIcon from "./Yand_maps_icon.png";
import MapWidgetIcon from "./map_widget.png";
import TwoGISIcon from "./2gis_icon.png";
import GoogleMapsIcon from "./Google_maps_icon.png";

function MapWidget(){

    return(<div className="map_widget">
        <img
            className="map_widget_img"
            src={MapWidgetIcon}
            alt="Map pic"
        />
        <div className="map_widget_row">
            <ButtonBlack icon={YandexMapsIcon} text="Яндекс Карты" url="https://yandex.ru/maps/-/CDU6b2zl" isNewTab={true}/>
            <ButtonBlack icon={TwoGISIcon} text="2ГИС" url="https://go.2gis.com/f6hqsh" isNewTab={true}/>
            <ButtonBlack icon={GoogleMapsIcon} text="Google Карты" url="https://www.google.com/maps/place/iBurger,+3-%D0%B9+%D0%BC%D0%B8%D0%BA%D1%80%D0%BE%D1%80%D0%B0%D0%B9%D0%BE%D0%BD,+32+%D0%B4%D0%BE%D0%BC,+%D0%9B%D1%83%D1%87%D0%B5%D0%B3%D0%BE%D1%80%D1%81%D0%BA,+%D0%9F%D1%80%D0%B8%D0%BC%D0%BE%D1%80%D1%81%D0%BA%D0%B8%D0%B9+%D0%BA%D1%80%D0%B0%D0%B9,+692001/@46.4457978,134.2997404,19z/data=!4m6!3m5!1s0x5eff6d69615af4eb:0xa8f38f21f8a5dbc0!8m2!3d46.4457643!4d134.2996885!16s%2Fg%2F11qpt82dfq?hl=ru" isNewTab={true}/>
        </div>
        <div className="map_widget_txtaddress user_selection_allowed">Лучегорск, 3-й микрорайон, дом 32 (этаж 1).</div>
    </div>);
}

export default MapWidget;