import {getCookie, isValueInstalled} from "./service-functions/service-functions";

const API_BASE_PATH = 'https://api.iburgerluch.ru/';

function getHeaders(){
    const BEARER_TOKEN = getCookie("authtoken");
    return {
        'Content-Type': 'application/json',
        'Authorization-Token': `Bearer ${BEARER_TOKEN}`
    };
}

export async function checkSessionRequest(){
    const REQUEST_PATH = 'auth/getAuth';
    const response = await fetch(`${API_BASE_PATH}${REQUEST_PATH}`, {
        method: 'GET',
        headers: getHeaders()
    });
    const result = await response.json();
    switch (response.status) {
        case 200:
            return result['data'];
        default:
            throw Error(response.status.toString());
    }
}

export async function removeCartItem(itemPos: number| null){
    const REQUEST_PATH = 'cart';
    const response = await fetch(`${API_BASE_PATH}${REQUEST_PATH}`, {
        method: 'DELETE',
        headers: getHeaders(),
        body: itemPos == null ? JSON.stringify({ delete_all: true }) : JSON.stringify({ item_pos: itemPos })
    });
    const result = await response.json();
    switch (response.status) {
        case 200:
            return result['data'];
        default:
            throw Error(response.status.toString());
    }
}

export async function repeatOrderRequest(order_id: number){
    const REQUEST_PATH = 'orders';
    const response = await fetch(`${API_BASE_PATH}${REQUEST_PATH}`, {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify({
            order_id: order_id
        })
    });
    const result = await response.json();
    switch (response.status) {
        case 200:
            return result['data'];
        default:
            throw Error(response.status.toString());
    }
}

export async function getMyOrdersRequest(filter: "all" | "active"){
    const REQUEST_PATH = 'orders';
    const response = await fetch(`${API_BASE_PATH}${REQUEST_PATH}?filter=${filter}`, {
        method: 'GET',
        headers: getHeaders(),
    });
    const result = await response.json();
    switch (response.status) {
        case 200:
            return result['data'];
        default:
            throw Error(response.status.toString());
    }
}

export async function signUpRequest(number: string, password: string, name: string, email?: string){
    const REQUEST_PATH = 'auth/signUp';
    const response = await fetch(`${API_BASE_PATH}${REQUEST_PATH}`, {
        method: 'POST',
        body: JSON.stringify({
            number: number,
            password: password,
            name: name,
            email: isValueInstalled(email) ? email : ''
        })
    });
    const result = await response.json();
    switch (response.status) {
        case 201:
            return result['data'];
        default:
            throw Error(response.status.toString());
    }
}

export async function signInRequest(number: string,password: string){
    const REQUEST_PATH = 'auth/signIn';
    const response = await fetch(`${API_BASE_PATH}${REQUEST_PATH}`, {
        method: 'POST',
        body: JSON.stringify({
            number: number,
            password: password
        })
    });
    const result = await response.json();
    switch (response.status) {
        case 200:
            return result['data'];
        default:
            throw Error(response.status.toString());
    }
}

export async function confirmOrderRequest(address: string,comment: string){
    const REQUEST_PATH = 'orders';
    const response = await fetch(`${API_BASE_PATH}${REQUEST_PATH}`, {
        method: 'POST',
        headers: getHeaders(),
        body: JSON.stringify({
            address: address,
            comment: comment
        })
    });
    const result = await response.json();
    switch (response.status) {
        case 200:
            return result['data'];
        case 400:
            throw Error(result['message']);
        default:
            throw Error(response.status.toString());
    }
}

export async function addToOrderRequest(item: number,cadditionals: string) {
    const REQUEST_PATH = 'cart';
    const response = await fetch(`${API_BASE_PATH}${REQUEST_PATH}`, {
        method: 'PUT',
        headers: getHeaders(),
        body: JSON.stringify({
            item_id: item,
            item_additionals: cadditionals
        })
    });
    const result = await response.json();
    switch (response.status) {
        case 200:
            return result['data'];
        default:
            throw Error(response.status.toString());
    }
}

export async function getMenuArrayRequest() {
    const REQUEST_PATH = 'getMenu';
    const response = await fetch(`${API_BASE_PATH}${REQUEST_PATH}`, {
        method: 'GET',
        headers: getHeaders()
    });
    const result = await response.json();
    switch (response.status) {
        case 200:
            return result['data'];
        default:
            throw Error(response.status.toString());
    }
}
export async function getCafeStatus() {
    const REQUEST_PATH = 'getCafeState';
    const response = await fetch(`${API_BASE_PATH}${REQUEST_PATH}`, {
        method: 'GET',
        headers: getHeaders()
    });
    const result = await response.json();
    switch (response.status) {
        case 200:
            return result['data'];
        default:
            throw Error(response.status.toString());
    }
}