import "./additionals-section-item.css";
import { convertStringToArray, getArrayById, filterSelectableSetions } from "../../service-functions/service-functions";
import { menuArrayReference } from "../../types";
import React, { useState, useEffect } from "react";
import checkIcon from "./checked_icon.png";
import {useSelector} from "react-redux";
import {RootState} from "../../redux/store";

interface propsAdditionalsSectionItem {
    secId: number,
    isSelectAllowed: boolean,
    onSelectUpdated: (id: number,additionals: number[]) =>void
}

function AdditionalsSectionItem ({ secId, isSelectAllowed, onSelectUpdated }: propsAdditionalsSectionItem){
    const menuState = useSelector((state: RootState) => state.menuState);
    const [selectedAdditionals,setSelectedAdditionals] = useState<number[]>([]);

    const MenuAdditionals = menuState.additionals;
    const thisSection = getArrayById(menuState.additionalsSections,secId)!;
    const additionalsIdsList = convertStringToArray(thisSection.additionals_list).map((item) => Number(item));
    const additionalsList = additionalsIdsList.map((itemId) => getArrayById(MenuAdditionals,itemId)!);
    const isOneSelect = Number(thisSection.selectrequare)===1;
    const isAllSelected = filterSelectableSetions(menuState,[secId]).length==0;

    const handleAdditionalClick = (id: number) => {

        if (!isSelectAllowed)
            return;

        setSelectedAdditionals(prevState => {
            if (isOneSelect){
                return [id];
            }
            if (prevState.includes(id)){
                return prevState.filter(item => item !== id);
            }
            return [...prevState,id].sort((a, b) => {
                const indexA = additionalsIdsList.indexOf(a);
                const indexB = additionalsIdsList.indexOf(b);
                return indexA - indexB;
            });
        });
    }

    useEffect(() =>{
        onSelectUpdated(secId, selectedAdditionals);
    }, [selectedAdditionals])

    return <>
        <div className="modal_bottom_right_item_additionals_list_title">{thisSection.title}</div>
        {additionalsList.map((additionalsListItem) => {
                const { id, img, name, price, checked } = additionalsListItem;
                return <AdditionalItem
                    key={'AdditionalItem'+id}
                    id={Number(id)}
                    img={img}
                    name={name}
                    price={Number(price)}
                    checked={isAllSelected || selectedAdditionals.includes(Number(id))}
                    clickable={Number(checked)===0}
                    handleClick={handleAdditionalClick}
                />;
            }
        )}
    </>;
}

export default AdditionalsSectionItem;

interface propsAdditionalItem {
    id: number,
    img: string,
    name: string,
    price: number,
    handleClick: (id: number) =>void,
    clickable: boolean,
    checked: boolean
}

function AdditionalItem({ id, img, name, price, handleClick, clickable, checked}: propsAdditionalItem){
    const itemClick = () => {
        if (clickable) handleClick(Number(id));
    }
    return(<div className={`modal_additional_item${!clickable ? ' require' : ''}`} onClick={itemClick}>
        <div
            className="modal_additional_item_left">
            <img src={img}
                 className="modal_additional_item_img"
                 alt="Additional img"
            />
            <div
                className="modal_additional_item_name">{name}
            </div>
            <div
                className="modal_additional_item_price">+{`${price} руб`}
            </div>
        </div>
        <div className={`modal_additional_item_check${checked ? ' act' : ''}`}>
            <img src={checkIcon} className="modal_additional_item_checkimg" alt="checked"/>
        </div>
    </div>);
}