import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {userOrderRef} from "../../types";
import {getCookie} from "../../service-functions/service-functions";

interface userState{
    clientToken:string|null,
    authStatus:undefined|boolean,
    userName:string,
    userDefaultAddress:string,
    userOrder:userOrderRef[],
    orderTotal:number
}
interface updateAuthUserState extends Omit<userState, 'userDefaultAddress'|'userOrder'|'orderTotal'> {
    userDefaultAddress?: string;
    userOrder?:userOrderRef[];
    orderTotal?:number;
}

const initialState: userState = {
    clientToken:getCookie('authtoken'),
    authStatus:undefined,
    userName:"",
    userDefaultAddress:"",
    userOrder:[],
    orderTotal:0
};

const userStateSlice = createSlice({
    name: "userState",
    initialState,
    reducers: {
        updateUserAuth: (state, action: PayloadAction<updateAuthUserState>) => {
            state.clientToken = action.payload.clientToken;
            state.authStatus = action.payload.authStatus;
            state.userName = action.payload.userName;
            if (action.payload.userDefaultAddress != null) state.userDefaultAddress = action.payload.userDefaultAddress;
            if (action.payload.userOrder != null) state.userOrder = action.payload.userOrder;
            if (action.payload.orderTotal != null) state.orderTotal = action.payload.orderTotal;
        },
        setUserDefaultAddress: (state, action: PayloadAction<Pick<userState, 'userDefaultAddress'>>) => {
            state.userDefaultAddress = action.payload.userDefaultAddress;
        },
        clearUserAuth: (state) => {
            state.clientToken = null;
            state.authStatus = false;
            state.userName = "";
            state.userDefaultAddress = "";
            state.userOrder = [];
            state.orderTotal = 0;
        },
        setUserOrder: (state, action: PayloadAction<Pick<userState, 'userOrder'|'orderTotal'>>) => {
            state.userOrder = action.payload.userOrder;
            state.orderTotal = action.payload.orderTotal;
        }
    }
});

export const { updateUserAuth, clearUserAuth,setUserDefaultAddress, setUserOrder } = userStateSlice.actions;
export default userStateSlice.reducer;