import "./small-button-orange.css";
import React from "react";
import LoadingIcon from "../loading-icon/loading-icon";

export interface ButtonOrangeProps {
    text: string,
    isLoading?: boolean,
    isDeactivated: boolean,
    onClick?: Function
}

const SmallButtonOrange = ({ text, isLoading, isDeactivated, onClick }: ButtonOrangeProps) => {

    const handleClick = () => {
        if (isLoading==null || !isLoading){
            onClick?.();
        }
    }

    return (
        <button
        className={`orange_button ${isDeactivated || isLoading!=null && isLoading ? 'deact' : ''}`}
        onClick={() => handleClick()}>
            {isLoading!=null && isLoading ? <LoadingIcon/> : <div className="orange_button_text">{text}</div>}
        </button>
    );
}

export default SmallButtonOrange;