import { configureStore } from '@reduxjs/toolkit';
import userStateReducer from "./user/user-state";
import menuStateReducer from "./menu/menuState";
import appStateReducer from "./app/app-state";

const store = configureStore({
    reducer: {
        userState:userStateReducer,
        menuState:menuStateReducer,
        appState:appStateReducer
    }
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export default store;
