import {useSelector} from "react-redux";
import {RootState} from "../redux/store";
import {useEffect} from "react";

export default function useTheme(){
    const isDarkTheme = useSelector((state: RootState) => state.appState.isDarkTheme);

    useEffect(() => {
        const body = document.body;
        if (isDarkTheme) {
            if (!body.classList.contains('dark')) body.classList.add('dark');
        } else {
            if (body.classList.contains('dark')) body.classList.remove('dark');
        }
    }, [isDarkTheme]);
}