import React, {useEffect, useState} from "react";
import "./modal-about-item.css";
import {
    convertStringToArray,
    getArrayById,
    filterSelectableSetions,
    countElementPrice,
    convertArrayToString, isNumeric,
    convertRequestToUserOrderType
} from "../../service-functions/service-functions";
import Modal from "../modal/modal";
import AdditionalsSectionItem from "../../components/additionals-section-item/additionals-section-item";
import {userOrderRef} from "../../types";
import {ButtonOrangeProps} from "../../components/button-orange/button-orange";
import {addToOrderRequest} from "../../requests";
import {useAlertMessage} from "../../components/alert-message/AlertMessageContext";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux/store";
import {clearUserAuth, setUserOrder} from "../../redux/user/user-state";
import {setOpenedModal} from "../../redux/app/app-state";
import {useLocation, useNavigate} from "react-router-dom";

interface PropsReference {
    handleForceUpdateMenu: () => void
}

function ModalAboutItem({handleForceUpdateMenu}: PropsReference) {
    const modalOpened = useSelector((state: RootState) => state.appState.modalOpened);
    const dishPicked = useSelector((state: RootState) => state.appState.dishPicked);
    const isThisModalOpened = modalOpened == 'about' && dishPicked != null;
    const clientToken = useSelector((state: RootState) => state.userState.clientToken);
    const menuState = useSelector((state: RootState) => state.menuState);

    const [isLoading, setLoading] = useState(false);
    const [totalItemPrice, setTotalItemPrice] = useState<number | undefined>(undefined);
    const [selectedAdditionals, setSelectedAdditionals] = useState<Array<number[]>>([]);

    const alertShowMessage = useAlertMessage();
    const dispatch = useDispatch();

    const location = useLocation();
    const navigate = useNavigate();

    const itemObject = getArrayById(menuState.menu, dishPicked);
    const itemSecAdditionalsList =
        convertStringToArray(itemObject?.additionals)
            .map((item) => getArrayById(menuState.additionalsSections, item)!);


    const getOnlySelectableElements = (onlyRequireSelect: boolean = false): number[] => {
        if (!menuState.isMenuReceived) return []
        return filterSelectableSetions(
            menuState,
            convertStringToArray(
                getArrayById(menuState.menu, dishPicked)?.additionals
            ),
            onlyRequireSelect
        )
    }
    //filter sections to find only selectable sections for user
    const additionalsToSelect = getOnlySelectableElements();

    const handleModalAboutUpdated = (id: number, additionals: number[]) => {
        if (isLoading) return;
        const curentAdditionals = selectedAdditionals.slice();
        curentAdditionals[additionalsToSelect.indexOf(id)] = additionals; //using only selectable sections for user
        setSelectedAdditionals(curentAdditionals);
        setTotalItemPrice(countElementPrice(menuState, dishPicked!, curentAdditionals)!);
    };

    const handleRequest = () => {
        if (itemObject == null || !(Number(itemObject.avalible) == 1 && Number(itemObject.deleted) == 0)) {
            alertShowMessage("Товара нет в наличии", false);
            return;
        }

        if (clientToken == null) {
            alertShowMessage("Для добавления блюд в корзину нужно войти в аккаунт", false);
            handleClose();
            dispatch(setOpenedModal({modalOpened: 'login'}));
            return;
        }

        for (let i = 0; i < getOnlySelectableElements(true).length; i++) {
            if (typeof selectedAdditionals[i] != 'object' || selectedAdditionals[i].length == 0) {
                const additionalsToSelect = filterSelectableSetions(
                    menuState,
                    convertStringToArray(
                        getArrayById(menuState.menu, dishPicked)!.additionals
                    )
                );

                alertShowMessage(getArrayById(menuState.additionalsSections, additionalsToSelect[i])!.title, false);
                return;
            }
        }
        setLoading(true);

        addToOrderRequest(dishPicked as number, convertArrayToString(selectedAdditionals, true)).then((result) => {
            dispatch(setUserOrder({
                userOrder:convertRequestToUserOrderType(result),
                orderTotal: Number(result[2])
            }));
            alertShowMessage(`"${itemObject.name}" добавлен в корзину`, true);
            handleClose();
        }).catch((error) => {
            switch (error.message) {
                case '404':
                    alertShowMessage("Товара нет в наличии", false);
                    handleForceUpdateMenu();
                    handleClose();
                    break;
                case '401':
                    dispatch(clearUserAuth());
                    alertShowMessage("Для добавления блюд в корзину нужно войти в аккаунт", false);
                    handleClose();
                    dispatch(setOpenedModal({modalOpened: 'login'}));
                    return;
                default:
                    if (isNumeric(error.message)) {
                        alertShowMessage("Server error: " + error.message, false);
                    } else {
                        alertShowMessage('Проверьте подключение к интернету', false);
                    }
            }
        }).finally(() => setLoading(false));
    }
    const handleClose = () => {

        //reset all states to default
        setLoading(false);
        setSelectedAdditionals([]);
        setTotalItemPrice(undefined);
        dispatch(setOpenedModal({modalOpened: null}));
    };

    useEffect(() => {
        if (isThisModalOpened)
            navigate(`?dish=${dishPicked}`);
        else
            navigate('');

    }, [isThisModalOpened]);
    useEffect(() =>{
        const queryParams = new URLSearchParams(location.search);
        const dishId = queryParams.get('dish');

        if (dishId){
            if (menuState.isMenuReceived && getArrayById(menuState.menu,dishId) && !isThisModalOpened){
                dispatch(setOpenedModal({ modalOpened:"about", dishPicked:Number(dishId) }));
            }
        } else if (isThisModalOpened) handleClose();

    },[location]);

    const buttonActive = itemObject != null ? (Number(itemObject.avalible) === 1 && Number(itemObject.deleted) === 0) : true,
        priceForButton = (totalItemPrice != null ? totalItemPrice : itemObject?.price), //defines price number
        priceTextForButton = priceForButton != null && itemObject?.avalible=="1" && itemObject?.deleted=="0" ? `В корзину ${priceForButton} ₽` : 'Будет позже', //text for button

        buttonModalConfigure: ButtonOrangeProps = {
            text: priceTextForButton,
            isLoading: isLoading,
            isDeactivated: !buttonActive,
            onClick: handleRequest
        };
    return (
        <Modal isVisible={isThisModalOpened}
               closable={!isLoading}
               button={buttonModalConfigure}
               handleClose={handleClose}>
            {menuState.isMenuReceived && itemObject != null && (
                <>
                    <div className="modal_bottom_left">
                        <img src={itemObject.img}
                             className="modal_bottom_left_item_img" id="modalAbout_img" alt="Menu item"/>
                    </div>
                    <div className="modal_bottom_right">
                        <div className="modal_bottom_right_item_title" id="modalAbout_title">{itemObject.name}</div>
                        <div className="modal_bottom_right_item_about" id="modalAbout_about">{itemObject.about}</div>
                        <div
                            className={`modal_bottom_right_item_delivery_available ${itemObject.delivery_avalible == "0" ? 'act' : ''}`}>ТОЛЬКО
                            САМОВЫВОЗ
                        </div>
                        {itemObject.composition && (
                            <div className="modal_bottom_right_item_composition" id="modalAbout_composition">
                                <b>Состав: </b>{itemObject.composition}
                            </div>
                        )}
                        <div
                            className={`modal_bottom_right_item_additionals_list opacity_anim_controller ${isLoading ? 'pending' : ''}`}>
                            {itemSecAdditionalsList.map(itemSecAdditionalsListItem => (
                                <AdditionalsSectionItem
                                    key={'AdditionalsSectionItem' + itemSecAdditionalsListItem.id}
                                    secId={Number(itemSecAdditionalsListItem.id)}
                                    isSelectAllowed={!isLoading}
                                    onSelectUpdated={handleModalAboutUpdated}
                                />
                            ))}
                        </div>
                    </div>
                </>
            )}
        </Modal>
    );
}

export default ModalAboutItem;