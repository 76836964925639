import "./modal-log-up.css";
import Modal from "../modal/modal";
import React, { useState } from "react";
import { ButtonOrangeProps } from "../../components/button-orange/button-orange";
import InputText from "../../components/input-text/input-text";
import {useAlertMessage} from "../../components/alert-message/AlertMessageContext";
import {
    convertRequestToUserOrderType,
    isNumeric,
    isValueInstalled,
    setCookie
} from "../../service-functions/service-functions";
import { signUpRequest } from "../../requests";
import {userOrderRef} from "../../types";
import Link from "../../components/link/link";
import {setUserOrder, updateUserAuth} from "../../redux/user/user-state";
import {useDispatch, useSelector} from "react-redux";
import {setOpenedModal} from "../../redux/app/app-state";
import {RootState} from "../../redux/store";

interface PropsReference {
    handleScrollToMenu: ()=> void
}

function ModalLogUp({ handleScrollToMenu }: PropsReference){
    const modalOpened = useSelector((state: RootState) => state.appState.modalOpened);
    const [isLoading, setLoading] = useState(false);
    const [enteredLogin, setEnteredLogin] = useState<string>("");
    const [enteredEmail, setEnteredEmail] = useState<string>("");
    const [enteredName, setEnteredName] = useState<string>("");
    const [enteredPassword, setEnteredPassword] = useState<string>("");
    const [enteredRepeatPassword, setEnteredRepeatPassword] = useState<string>("");

    const alertShowMessage = useAlertMessage();
    const dispatch = useDispatch();

    const handleClose = () => {

        //reset all states to default
        setLoading(false);
        setEnteredLogin("");
        setEnteredName("");
        setEnteredEmail("");
        setEnteredPassword("");
        setEnteredRepeatPassword("");
        dispatch(setOpenedModal({ modalOpened:null }));
    };
    const handleLogInOpen = () => dispatch(setOpenedModal({ modalOpened:'login' }));
    const handleRequest = () =>{
        if (!isValueInstalled(enteredLogin)){
            alertShowMessage("Введите телефон",false);
        }else if (!isValueInstalled(enteredName)){
            alertShowMessage("Введите ваше имя",false);
        }else if (!isValueInstalled(enteredPassword)){
            alertShowMessage("Введите пароль",false);
        }else if (!isValueInstalled(enteredRepeatPassword)){
            alertShowMessage("Введите пароль повторно",false);
        }else if (enteredPassword != enteredRepeatPassword){
            alertShowMessage("Пароли не совпадают",false);
        }else {
            setLoading(true);
            signUpRequest(enteredLogin,enteredPassword,enteredName,enteredEmail).then((result) =>{
                setCookie("authtoken",result["user_token"],31536000,"/",true);
                dispatch(updateUserAuth({
                    clientToken:result["user_token"].toString(),
                    authStatus:true,
                    userName:result["name"],
                    userDefaultAddress:""
                }));
                const userOrder = result['user_order'];
                dispatch(setUserOrder({
                    userOrder:convertRequestToUserOrderType(userOrder),
                    orderTotal: Number(userOrder[2])
                }));
                alertShowMessage("Вы успешно зарегестрировались",true);
                handleClose();
                handleScrollToMenu();
            }).catch((error) =>{
                switch (error.message){
                    case '401':
                        alertShowMessage("Вы уже зарегестрированы, войдите в аккаунт",false);
                        break;
                    case '400':
                        alertShowMessage("Введите телефон в корректном формате",false);
                        break;
                    default:
                        if (isNumeric(error.message)){
                            alertShowMessage("Server error: "+error.message, false);
                        }else {
                            alertShowMessage('Проверьте подключение к интернету',false);
                        }
                }
            }).finally(() => setLoading(false))
        }
    }

    const buttonModalConfigure: ButtonOrangeProps = {
        text: "Зарегистрироваться",
        isLoading: isLoading,
        isDeactivated: false,
        onClick: handleRequest
    };
    return(
        <Modal isVisible={modalOpened=='logup'}
               closable={!isLoading}
               button={buttonModalConfigure}
               handleClose={handleClose}>
            <center className={`opacity_anim_controller ${isLoading ? 'pending' : ''}`}>
                <h3 className="modal_auth_title">Регистрация</h3>
                <InputText type="text" placeholder="Телефон" onChangeText={setEnteredLogin} value={enteredLogin} fill={false}/>
                <InputText type="text" placeholder="Ваше имя" onChangeText={setEnteredName} value={enteredName} fill={false}/>
                <InputText type="email" placeholder="E-Mail (необязательно)" onChangeText={setEnteredEmail} value={enteredEmail} fill={false}/>
                <InputText type="password" placeholder="Пароль" onChangeText={setEnteredPassword} value={enteredPassword} fill={false}/>
                <InputText type="password" placeholder="Повторите пароль" onChangeText={setEnteredRepeatPassword} value={enteredRepeatPassword} fill={false}/>
                <div className="modal_auth_subtitle">Есть аккаунт? <Link onClick={handleLogInOpen}>Войти</Link></div>
            </center>
        </Modal>);
}
export default ModalLogUp;