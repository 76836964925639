import "./interactive-card.css";
import React from "react";

interface propsReference {
    img: string,
    title: string,
    subtitle: string,
    isLeft: boolean,
    accentColor: string,
    backgroundColor: string,
    children: React.ReactNode
}

function InteractiveCard({ img, title, subtitle, isLeft, accentColor, backgroundColor, children }: propsReference){

    const accentStyleBackground = {background:backgroundColor};
    const backgroundStyleColor = {color:accentColor};

    const image = <img
        className="app_download_block_imgs_item"
        src={img}
        alt="Card pic"
    />;

    const blockCircle =
        <div className="app_download_block_circle">
        <svg className={isLeft ? 'app_download_block_circle_body' : 'app_download_block_circle_body_right'}>
            {isLeft ?
                (<circle cx="80" cy="180" r="280" stroke="" strokeWidth="3" fill={accentColor}></circle>)
                : (<circle cx="320" cy="180" r="280" stroke="" strokeWidth="3" fill={accentColor}></circle>)}
        </svg>
    </div>;

    const images =
        <div className={isLeft ? 'app_download_block_imgs' : 'app_download_block_imgs_right'}>
            {isLeft ? (<>{blockCircle}{image}</>) : (<>{image}{blockCircle}</>)}
        </div>;

    const texts =
        <div className="app_download_block_texts">
            <div className="app_download_block_texts_title">{title}</div>
            <div className="app_download_block_texts_subtitle" style={backgroundStyleColor}>{subtitle}</div>
            {children}
        </div>;

    return(
        <div className={isLeft ? 'app_download_block left' : 'app_download_block'} style={accentStyleBackground}>
            {isLeft ? (<>{images}{texts}</>) : (<>{texts}{images}</>)}
        </div>
    );
}

export default InteractiveCard;