import {useDispatch} from "react-redux";
import {switchAppTheme} from "../../redux/app/app-state";
import {useAlertMessage} from "../alert-message/AlertMessageContext";
import themeIcon from "./dark_mode_icon.svg";
import "./change-theme-button.css";
function ChangeThemeButton(){
    const alertShowMessage = useAlertMessage();
    const dispatch = useDispatch();
    const switchTheme = () => {
        dispatch(switchAppTheme());
        alertShowMessage('Вы сменили тему', true);
    }
    return <button className="change_theme_button" onClick={switchTheme}><img src={themeIcon} className="change_theme_button_img" alt="Switch theme"/></button>;
}
export default ChangeThemeButton;