import "./button-orange.css";
import React from "react";
import LoadingIcon from "../loading-icon/loading-icon";

export interface ButtonOrangeProps {
    text: string,
    isLoading?: boolean,
    isDeactivated: boolean,
    onClick?: () => void
}

const ButtonOrange = ({ text, isLoading, isDeactivated, onClick }: ButtonOrangeProps) => {

    const handleClick = () => {
        if (isLoading==null || !isLoading){
            onClick?.();
        }
    }

    return (
        <button
        className={`modal_bottom_button ${isDeactivated || isLoading!=null && isLoading ? 'deact' : ''}`}
        onClick={() => handleClick()}>
            {isLoading!=null && isLoading ? <LoadingIcon/> : <div className="modal_bottom_button_text">{text}</div>}
        </button>
    );
}

export default ButtonOrange;